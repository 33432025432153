import {
    PublicClientApplication,
    AccountInfo,
    AuthenticationResult,
} from '@azure/msal-browser';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState, store } from '../..';
import { selectMicrosoftAppId } from '../../selectors/app';
import {
    startFetch,
    OperationId,
    stopFetch,
    showNotification,
} from '../app/actionCreators';
import { setMsalAuthResult } from './actions';
import { batch } from 'react-redux';
import { storeKeys } from '../../../helpers';
import { msalRefBox } from '../../../services/statusSync/msalRefBox';
import { AppErrorCode, NotificationStatus } from '../app/actionTypes';
// eslint-disable-next-line max-len
import { authenticateMsTeams } from '../../../services/statusSync/authentication';
import { selectTranslate } from '../../selectors/ui';

export const setMsalAuthResultThunk = (
    authResult: AuthenticationResult | null,
): ThunkAction<void, RootState, unknown, AnyAction> =>
    function thunk(dispatch) {
        const msal = msalRefBox.getValue();
        if (!msal) {
            return;
        }

        msal.setActiveAccount(authResult?.account ?? null);
        dispatch(setMsalAuthResult(authResult));
    };

export const setMsalActiveAccountThunk = (
    account: AccountInfo,
): ThunkAction<void, RootState, unknown, AnyAction> =>
    async function thunk(dispatch, _getState): Promise<void> {
        const msal = msalRefBox.getValue();
        if (!msal) {
            return;
        }

        let authRes: AuthenticationResult;
        try {
            authRes = await msal.ssoSilent({
                account,
            });
        } catch (err) {
            console.error(err);
            return;
        }

        try {
            authRes ??= await msal.acquireTokenSilent({
                account,
                scopes: [],
            });
        } catch (err) {
            console.error(err);
            return;
        }

        try {
            authRes ??= await msal.loginPopup({
                account,
                scopes: [],
            });
        } catch (err) {
            console.error();
            return;
        }

        dispatch(setMsalAuthResultThunk(authRes));

        const login_hint = account?.idTokenClaims?.login_hint;
        if (!login_hint) {
            return;
        }

        localStorage.setItem(storeKeys.MSAL_LOGIN_HINT_KEY, login_hint);
    };

export const authenticateMsTeamsThunk = (
    ignorePromptLogin: boolean = false,
): ThunkAction<
    Promise<AuthenticationResult | null>,
    RootState,
    unknown,
    AnyAction
> =>
    async function thunk(
        dispatch,
        getState,
    ): Promise<AuthenticationResult | null> {
        const translate = selectTranslate(getState());

        dispatch(
            startFetch({
                id: OperationId.AuthenticateMsTeams,
                message: translate('ms_login'),
            }),
        );

        const msAppId = selectMicrosoftAppId(store.getState());
        if (!msAppId) {
            dispatch(
                stopFetch({
                    operationId: OperationId.AuthenticateMsTeams,
                }),
            );
            return null;
        }

        const msal = new PublicClientApplication({
            auth: {
                clientId: msAppId,
                redirectUri: `${window.location.origin}/redirect.html`,
            },
            cache: {
                cacheLocation: 'localStorage',
                storeAuthStateInCookie: false,
            },
        });
        await msal.initialize();
        msalRefBox.setValue(msal);

        const authRes = await authenticateMsTeams(ignorePromptLogin);
        if (!authRes) {
            dispatch(
                stopFetch({
                    operationId: OperationId.AuthenticateMsTeams,
                }),
            );
            return null;
        }

        batch(() => {
            dispatch(setMsalAuthResultThunk(authRes));
            dispatch(
                stopFetch({
                    operationId: OperationId.AuthenticateMsTeams,
                }),
            );
        });
        return authRes;
    };

export const reauthenticateMsTeamsThunk = (): ThunkAction<
    void,
    RootState,
    unknown,
    AnyAction
> =>
    async function thunk(dispatch, getState): Promise<void> {
        const translate = selectTranslate(getState());

        const authRes = await authenticateMsTeams();

        if (!authRes) {
            dispatch(
                showNotification({
                    status: NotificationStatus.Error,
                    message: translate('ms_auth_fail'),
                    errorCode: AppErrorCode.AuthenticationFailed,
                }),
            );
        }

        dispatch(setMsalAuthResultThunk(authRes));
    };
