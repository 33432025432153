import { z } from 'zod';
import { dndFilesSchema } from './shared';
import { expirationDateRefinement, passwordRefinement } from './refinements';

export type ShareFileData = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    files: any[];
    password?: string;
    expirationDate?: Date | null;
    shareWithoutPassword?: boolean;
};

export const shareFileSchema: z.ZodSchema<ShareFileData> = z.object({
    files: dndFilesSchema,
    password: z.string().optional().superRefine(passwordRefinement),
    expirationDate: z
        .date()
        .optional()
        .nullable()
        .superRefine(expirationDateRefinement),
    shareWithoutPassword: z.boolean().optional(),
}) as z.ZodSchema<ShareFileData>;
