import React, { CSSProperties, useEffect, useState } from 'react';
import {
    Text,
    IContextualMenuItem,
    Stack,
    PrimaryButton,
    ContextualMenu,
    IContextualMenuProps,
    IconButton,
    IButtonStyles,
    IIconProps,
    IStackStyles,
    IStackTokens,
} from '@fluentui/react';
import { Logger, appStyles } from '../../../helpers';
import { useAppDispatch, useAppSelector } from '../../../store';
import { appActions } from '../../../store/actions/app/actions';
import { selectTranslate } from '../../../store/selectors/ui';
import { useWizard } from '../../../wizard';
import { FileOverwriteDialog, ServerFilesDialog } from '../../dialogs';
import WizardFiles from '../files';
import { PageHeading } from '../../common/pageHeading';
import { WizardFooter } from '../footer';
import { useFilePicker } from 'use-file-picker';
import { getRepeatedFileName } from '../../../helpers/file';
import {
    selectDefaultUploadFolder,
    selectDestinationFolder,
    selectStubUrl,
} from '../../../store/selectors/app';
import {
    uploadShareFileThunk,
    UploadShareFileThunkError,
} from '../../../store/actions/app/thunks';
import { selectUser, selectUserId } from '../../../store/selectors/auth';
import { countFilenameRepeats } from '../../../services/file';

const containerTokens: IStackTokens = { childrenGap: 10 };

const textStyle: CSSProperties = {
    fontSize: 14,
    fontStyle: 'normal',
    marginTop: 0,
};

const uploadContainerStyles: IStackStyles = { root: { alignItems: 'center' } };
const uploadButtonContainerTokens: IStackTokens = { childrenGap: 8 };

const folderIconButtonStyles: IButtonStyles = {
    root: { color: '#F36928 !important' },
};
const folderIconProps: IIconProps = {
    iconName: 'FabricFolder',
};

const wizardFilesContainerTokens: IStackTokens = { childrenGap: 15 };

export const SelectFileStep = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const translate = useAppSelector(selectTranslate);
    const destinationFolder = useAppSelector(selectDestinationFolder);
    const user = useAppSelector(selectUser);
    const stubUrl = useAppSelector(selectStubUrl);
    const userId = useAppSelector(selectUserId);
    const defaultUploadFolder = useAppSelector(selectDefaultUploadFolder);

    const { setWizardStep } = useWizard();

    const [filesDialogHidden, setFilesDialogHidden] = useState(true);
    const [foldersDialogHidden, setFoldersDialogHidden] = useState(true);

    const [
        openFileSelector,
        {
            filesContent,
            plainFiles,
            clear: clearFiles,
            loading: areFilesLoading,
        },
    ] = useFilePicker({
        readAs: 'ArrayBuffer',
        multiple: false,
    });

    const [newFilename, setNewFileName] = useState('');
    const [fileOverwriteDialogHidden, setFileOverwriteDialogHidden] =
        useState(true);

    const uploadPath = destinationFolder
        ? destinationFolder.path
        : defaultUploadFolder;

    const file = plainFiles[0];

    useEffect(() => {
        if (!file || areFilesLoading) {
            return;
        }

        const uploadFile = async () => {
            const serverFileRes = await dispatch(
                uploadShareFileThunk({
                    folder: uploadPath,
                    content: await file.arrayBuffer(),
                    fileBase: file.name,
                    allowOverwrite: false,
                }),
            );

            if (serverFileRes instanceof Error) {
                console.error(serverFileRes);

                switch (serverFileRes.cause) {
                    case UploadShareFileThunkError.FileExists:
                        const filenameRepeatCount = await countFilenameRepeats({
                            filename: file.name,
                            folder: uploadPath,
                            initialRepeatCount: 1,
                            requestConfig: {
                                stubUrl,
                                user,
                                userId,
                            },
                        });
                        setNewFileName(
                            getRepeatedFileName(file.name, filenameRepeatCount),
                        );
                        setFileOverwriteDialogHidden(false);
                        return;
                    default:
                        return clearFiles();
                }
            }

            clearFiles();
        };

        uploadFile();
    }, [filesContent.length, areFilesLoading]);

    const onSelectLocalFile = (
        _ev?: React.MouseEvent<HTMLButtonElement>,
        _item?: IContextualMenuItem,
    ): void => {
        Logger.info('onSelectLocalFile called');
        openFileSelector();
    };

    const onSelectServerFile = (
        _ev?: React.MouseEvent<HTMLButtonElement>,
        _item?: IContextualMenuItem,
    ): void => {
        dispatch(appActions.getServerFiles(''));
        setFilesDialogHidden(false);
        Logger.info('onSelectServerFile called');
    };

    const onDestinationClick = (): void => {
        dispatch(appActions.getServerFiles(''));
        setFoldersDialogHidden(false);
    };

    const onOverwrite = async (overwrite: boolean): Promise<void> => {
        if (!file) {
            return;
        }

        const filename = overwrite ? file.name : newFilename;

        await dispatch(
            uploadShareFileThunk({
                folder: uploadPath,
                content: await file.arrayBuffer(),
                fileBase: filename,
                allowOverwrite: true,
            }),
        );
    };

    const menuProps: IContextualMenuProps = {
        items: [
            {
                key: 'localFiles',
                text: translate('main_page_local_files'),
                onClick: onSelectLocalFile,
            },
            {
                key: 'serverFiles',
                text: translate('main_page_server_files'),
                onClick: onSelectServerFile,
            },
        ],
        isBeakVisible: false,
        useTargetWidth: true,
    };

    const getMenu = (props: IContextualMenuProps): JSX.Element => {
        // Customize contextual menu with menuAs
        return <ContextualMenu {...props} />;
    };

    return (
        <>
            <Stack tokens={containerTokens}>
                <PageHeading text="Share files" />
                <Text style={textStyle}>{translate('sharefiles_desc')}</Text>
                <Stack tokens={{ childrenGap: 10 }}>
                    <PrimaryButton
                        styles={appStyles.primaryButton}
                        style={{ width: '100%' }}
                        menuProps={menuProps}
                        menuAs={getMenu}
                        text={translate('sharefiles_localfiles_selectfile')}
                    />
                    <Stack
                        horizontal={true}
                        styles={uploadContainerStyles}
                        tokens={uploadButtonContainerTokens}
                    >
                        <IconButton
                            styles={folderIconButtonStyles}
                            iconProps={folderIconProps}
                            onClick={onDestinationClick}
                        />
                        <Text>{uploadPath}</Text>
                    </Stack>
                </Stack>
                <Stack tokens={wizardFilesContainerTokens}>
                    <WizardFiles
                        wizardPos={-1}
                        onEdit={setWizardStep}
                        onDestination={onDestinationClick}
                        hideDestinationFolder={true}
                    />
                </Stack>
            </Stack>
            <WizardFooter />
            <ServerFilesDialog
                hidden={filesDialogHidden}
                onDismiss={setFilesDialogHidden}
                renderFiles={true}
            />
            <ServerFilesDialog
                hidden={foldersDialogHidden}
                onDismiss={setFoldersDialogHidden}
                renderFiles={false}
            />
            <FileOverwriteDialog
                hidden={fileOverwriteDialogHidden}
                onDismiss={setFileOverwriteDialogHidden}
                fileName={file?.name ?? ''}
                fileNewName={newFilename}
                onOverwrite={onOverwrite}
            />
        </>
    );
};
