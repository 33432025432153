import { RootState } from '..';

export const selectUserConfig = (state: RootState) =>
    state.authentication.userConfig;

export const selectUser = (state: RootState) => state.authentication.user;

export const selectUserSettings = (state: RootState) =>
    state.authentication.userSettings;

export const selectUserId = (state: RootState) =>
    state.authentication.userConfig.userInfo.id;

export const selectFileSharingSettings = (state: RootState) =>
    state.authentication.filesSharingSettings;
